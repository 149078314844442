<template>
  <div class="modal-item">{{ label }}</div>
</template>

<script>
export default {
  props: ["label"],
};
</script>

<style lang="scss" scoped>
.modal-item {
  @include Flex(inherit, center, center);
  border-top: solid 1px $alto;
  padding: $mpadding $mpadding;
  cursor: pointer;
  &:active {
    @include d-active();
  }
}
</style>
